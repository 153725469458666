var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Editor',{attrs:{"url":_vm.url,"fields":_vm.fields,"filters":_vm.filters,"icon":"mdi-account-group","testo-list":"Lista utenti","testo-insert":"Nuovo utente","testo-update":"Modifica utente","testo-delete":"Vuoi cancellare l'utente?"},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var instance = ref.instance;
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors.email,"label":"E-Mail","type":"email"},model:{value:(instance.email),callback:function ($$v) {_vm.$set(instance, "email", $$v)},expression:"instance.email"}}),_c('v-text-field',{attrs:{"error-messages":errors.first_name,"label":"Nome"},model:{value:(instance.first_name),callback:function ($$v) {_vm.$set(instance, "first_name", $$v)},expression:"instance.first_name"}}),_c('v-text-field',{attrs:{"error-messages":errors.last_name,"label":"Cognome"},model:{value:(instance.last_name),callback:function ($$v) {_vm.$set(instance, "last_name", $$v)},expression:"instance.last_name"}}),_c('v-text-field',{attrs:{"error-messages":errors.indirizzo,"label":"Indirizzo"},model:{value:(instance.indirizzo),callback:function ($$v) {_vm.$set(instance, "indirizzo", $$v)},expression:"instance.indirizzo"}}),_c('v-text-field',{attrs:{"error-messages":errors.comune,"label":"Comune"},model:{value:(instance.comune),callback:function ($$v) {_vm.$set(instance, "comune", $$v)},expression:"instance.comune"}}),_c('v-select',{attrs:{"items":_vm.ruoli,"error-messages":errors.ruolo,"label":"Ruolo"},model:{value:(instance.ruolo),callback:function ($$v) {_vm.$set(instance, "ruolo", $$v)},expression:"instance.ruolo"}}),_c('Relation',{attrs:{"url":"/backend/autocomplete/regione","label":"Regioni","multiple":""},model:{value:(instance.regioni),callback:function ($$v) {_vm.$set(instance, "regioni", $$v)},expression:"instance.regioni"}}),_c('Relation',{attrs:{"url":"/backend/autocomplete/gruppo","label":"Gruppi","multiple":""},model:{value:(instance.gruppi),callback:function ($$v) {_vm.$set(instance, "gruppi", $$v)},expression:"instance.gruppi"}})]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('Confirm',{attrs:{"message":("Cambio password: " + (item.full_name))},on:{"confirm":function($event){return _vm.updatePassword(item)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"attrs":attrs,"color":"lime","small":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-lock")])],1)]}},{key:"default",fn:function(){return [_c('v-text-field',{attrs:{"type":"password"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }